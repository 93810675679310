<template>
    <div class="product-item">
        <div>
            <div class="product-item__image-container">
                <product-badges
                    v-if="!!product.is_limited_series || !!product.is_long_term_production"
                    position="bottom-left"
                    :is-limited-series="!!product.is_limited_series"
                    :is-long-term-production="!!product.is_long_term_production"
                />
                <a
                    class="cursor-pointer product-item__image-link"
                    @click="goToProductCard(productCardParams)"
                >
                    <img
                        v-if="product.image_id"
                        class="cover"
                        alt=""
                        :src="getProductImageSrc(product.image_id)"
                    />
                    <img
                        v-else
                        src="@/assets/images/default-product-image.jpg"
                        alt=""
                        class="cover"
                    />
                </a>
            </div>
            <a
                :class="{
                    'offers product-item__link cursor-pointer hover-underline': true,
                    'h-25': isCurrentUserStructuralSubdivisionUser
                }"
                @click="goToProductCard(productCardParams)"
            >
                {{ product.name }}
                <span v-if="product.quantity && product.quantity > 1">
                    ({{ product.quantity }} шт.)
                </span>
            </a>
        </div>
        <div class="product-item__info">
            <p v-if="isCurrentUserDealer && product.price > 0" class="price">
                {{ product.price | fractionSizeSpace }}
                {{ makeDeclension(product.price, "балл", "балла", "баллов") }}
            </p>
            <template v-if="isCurrentUserStructuralSubdivisionUser">
                <p class="price f-size-14">
                    Доступно для заказа:
                </p>
                <p class="price f-size-14">
                    {{ product.balance }}
                    <small>шт.</small>
                </p>
            </template>
            <div v-if="isCurrentUserDealer" class="price-for-item">
                <div v-if="product.price_for_unit" class="offers">
                    {{ product.price_for_unit | fractionSizeSpace }} за 1 шт.
                </div>
                <p v-if="product.count" class="offers-count">
                    В наличии:
                    {{ product.count.count }}
                    шт.
                </p>
            </div>
            <template v-if="!closePurchaseByDate">
                <div
                    v-if="product.is_available > 0 || isCurrentUserStructuralSubdivisionUser"
                    class="product-actions"
                >
                    <template v-if="availableAddToBasket">
                        <button
                            v-if="!showSizeSelect"
                            class="send-to-basket"
                            type="button"
                            @click="addItem"
                        >
                            В корзину
                        </button>
                        <div v-else class="size-select">
                            <p class="size-select__label">Выберите размер:</p>
                            <v-select
                                :options="product.sizes"
                                v-model="selectedSize"
                                label="name"
                                class="sizes size-select__select"
                                append-to-body
                                ref="sizeSelect"
                                @input="selectSize"
                            />
                        </div>
                    </template>
                    <button v-if="!itemIsInCart && product.price == 0" class="item-in-basket" type="button" disabled>
                        Недоступен для заказа
                    </button>
                </div>
                <div v-if="product.is_available === 0" class="product-actions">
                    <button
                        class="item-in-basket default-cursor"
                        type="button"
                    >
                        Временно отсутствует
                    </button>
                </div>
            </template>
            <div v-else class="product-actions">
                <button class="closeOrderLabel" type="button" @click="showWarningAlert">
                    Недоступно до <br>
                    {{ closeOrdersDealerByDate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {success} from "@/lib/toasted";
import {showPurchaseByDate} from "@/lib/swal2";
import EntityMixin from "@/mixins/EntityMixin";
import ProductBadges from './ProductBadges.vue'

export default {
    name: "PopularProductsItem",
    mixins: [EntityMixin],
    components: { ProductBadges },
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        selectedSize: null,
        showSizeSelect: false,
    }),
    methods: {
        ...mapActions('cart', ['addProduct', 'goToCart']),
        ...mapActions('catalog', ['goToProductCard']),
        async addItem() {
            if (this.sizesExist) {
                this.showSizeSelect = true;
                this.openSizeSelect();
                success('Пожалуйста выберите размер.');
            } else {
                if (this.isCurrentUserStructuralSubdivisionUser && this.product.balance <= this.itemsInCart)
                    return false;
                await this.addProduct({productId: this.product.id})
            }
        },
        async selectSize(size) {
            await this.addProduct({productId: this.product.id, size: size.id})
        },
        openSizeSelect() {
            this.$refs.sizeSelect.open = true;
        },
        showWarningAlert() {
            showPurchaseByDate('Прием заказов прекращен до ' + this.closeOrdersDealerByDate + '. С информацией можно ознакомиться в разделе <a style="text-decoration: underline;" href="/how-to-order">«Как заказать?»</a>')
        }
    },
    computed: {
        ...mapGetters('account', ['isCurrentUserStructuralSubdivisionUser']),
        ...mapGetters('cart', ['isProductInCart', 'itemsInCart']),
        ...mapGetters('common', ['getProductImageSrc', 'makeDeclension']),
        ...mapGetters('builtInParameter', ['closeOrdersDealerByDate']),
        closePurchaseByDate() {
            return this.closeOrdersDealerByDate !== '' &&
                !(this.isCurrentUserDealer && this.product.count);
        },
        itemIsInCart() {
            return this.isProductInCart(this.product.id);
        },
        productCardParams() {
            const product = this.product;
            return {
                product,
                sectionPath: product.section_path,
                categoryPath: product.category_path,
                productPath: product.slug
            };
        },
        availableAddToBasket() {
            return this.product.price > 0 && this.product.is_available > 0;
        },
        sizesExist() {
            return this.product.sizes && this.product.sizes.length > 0
        },
    }
}
</script>

<style lang="sass" scoped>
.product-item
    padding-bottom: 25px
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    &__link
        margin: 20px 0
    &__image-container
      position: relative
    &__image-link, &__image-link img
      width: 100%
    &__info
        display: flex
        flex-direction: column
        align-items: baseline
.product-actions
    display: flex
    flex-direction: column
    flex: 1
    justify-content: flex-end

.default-cursor
    cursor: default


.f-size-14
    font-size: 14px


.h-25
    height: 25px

.cover
    object-fit: cover
    height: 279px

.size-select
    &__label
        font-size: 14px
        font-weight: bold
        margin-bottom: 0.3rem

    &__select
        margin-top: 9px
        width: 150px

.dropdown-menu::v-deep
    z-index: 10030 !important
.closeOrderLabel
    border: none
</style>
